import React,{Component} from 'react';
import styled from 'styled-components';
import { NavContextConsumer } from '../../utility/Contexts/NavigationContext';

import BackButton from '../../components/ui/Button/BackButton';
import LoadingSpinner from '../../components/ui/LoadingSpinner/LoadingSpinner';
import Photo360Engine from '../../components/common/New360Engine';
import { LocationContext } from '../../utility/Contexts/LocationContext';

const Image360Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
`;

class Image360View extends Component{

    render(){
        const {data,portals,pois,getPortal,dataLoading,vrEnabled, renderState} = this.context;

        if(dataLoading) return <LoadingSpinner/>
        return(
            <Image360Wrapper>
                    <NavContextConsumer>
                            {({ onItemChange }) => <>
                                                        <Photo360Engine location={data} image={data.image} portals={portals} pois={pois} onPortalClicked={getPortal} vrEnabled={vrEnabled} renderState={renderState}/>
                                                        <BackButton onClickHandler={() => onItemChange({id:'map',pageName:'Map Screen'})} />
                                                    </>}                    
                    </NavContextConsumer>
            </Image360Wrapper>
        )
    }
}

Image360View.contextType = LocationContext;
export default Image360View;