import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    ${({margin="0"})=>`
    margin:${margin};
    `}
    padding: 0 15px;
    min-width: 40px;
    height: 36px;
    border-radius: 18px;
    border: solid 1px #387f65;
    box-shadow: 0px 1px 3.6px 0.4px rgba(0, 0, 0, 0.43);
    outline: none;
    background-color:#fff;
    font-size: 16px;
    letter-spacing: -0.8px;
    color: #2d7865;
    cursor: pointer;
    text-transform:uppercase;
    vertical-align: middle;
`

const Button = ({ margin, children="Button", onClickHandler=()=>{alert("Clicked")}}) => <StyledButton margin={margin} onClick={() => onClickHandler()}>{children}</StyledButton>;

export default Button;