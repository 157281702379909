import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
${({ margin = "0" })=>`
    margin:${margin};
    `}
    min-width: 105px;
    height: 36px;
    padding: 0 20px;
    font-size: 16px;
    line-height: 36px;
    color: #ffffff;
    background-color: #59c9e5;
    border-radius: 37px;
    border: none;
    outline: none;
    cursor: pointer;
`;

const LoginButton = ({margin, children="Login Button", onClickHandler=()=>{alert("Clicked")}}) => <StyledButton margin={margin} onClick={() => onClickHandler()}>{children}</StyledButton>;

export default LoginButton;
