import React from 'react';
import styled from 'styled-components';
import { NavContextConsumer } from '../../utility/Contexts/NavigationContext';

import UserMenu from '../../components/ui/UserMenu/UserMenu';
import Burger from '../../components/ui/Burger/Burger';
import BackButton from '../../components/ui/Button/BackButton';
import Image from '../../resources/img/about-project.png';

const PageWrapper = styled.div`
    width: 100%;
    min-height: 100%;
    padding-bottom: 100px;
    background: rgba(172, 234, 211, 0.4);
`;

const ImageWrapper = styled.div`
    height: 35vh;
    background: url(${Image}) center / cover no-repeat;
`;

const ContentWrapper = styled.div`
    padding: 20px 40px;
`;

const Heading = styled.h1`
    position: relative;
    margin-bottom: 20px;
    padding-left: 5px;
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
    color: #2D7865;
    &::before {
        position: absolute;
        content: '';
        display: block;
        top: -1px
        left: 0;
        bottom: -1px;
        width: 1px;
        background-color: #2D7865;
    }
`;

const Text = styled.p`
    font-size: 16px;
    text-align: justify;
    color: #2D7865;
`;

const AboutProject = () => {
    return(
        <PageWrapper>
            <ImageWrapper />

            <ContentWrapper>
                <Heading>About Project</Heading>
                <Text><strong>360 NATURE</strong> is mobile application developed within AREeCcDev Interreg IPA CBC HUSRB Project by Institute of Lowland Forestry and Environment (ILFE). Its intention is promotion of protected natural areas, with main aim of improving ecoturism and rural development. It highlights extraordinary natural scenes through giving opportunity for complete immersion using state-of-the-art virtual reality technology.</Text>
            </ContentWrapper>

            <NavContextConsumer>
                {({ menuOpen, onMenuToggle, onItemChange }) => 
                        <React.Fragment>
                            {menuOpen
                                ?   <UserMenu onMenuToggle={onMenuToggle}  onChangeActiveItem = {onItemChange} />
                                :   <React.Fragment>
                                        <BackButton onClickHandler={() => onItemChange({id:'map',pageName:'Map Screen'})} />
                                        <Burger onClickHandler = {() => onMenuToggle()} />
                                    </React.Fragment>
                            }
                        </React.Fragment>
                    }
            </NavContextConsumer>
        </PageWrapper>
    )
}

export default AboutProject;