import React, { Component } from 'react';
import {AxiosInstance} from '../Communication/CommApi';
import {NavContext} from './NavigationContext';
const LocationContext = React.createContext();

class LocationContextProvider extends Component {
    //function for getting zie dejta
    getAllLocations = async ()=>{
        try {
            const response = await AxiosInstance().get('/api/locations');

            this.setState({
                ...this.state,
                locations: [...response.data],
                dataLoading:false,
                breadCrumbs:[]
            })
        } catch (error) {
            this.setState({
                ...this.state,
                dataLoading:false,
                breadCrumbs:[]
            })
        }       
    }

    getLocation = async (data)=>{
        // const currentItem = this.state.breadCrumbs[this.state.breadCrumbs.length-1];        
        // if(currentItem && currentItem.parentId===data.parentId) return;
            let config = {                      
                params:{
                    locationId:data.locationId,
                    parentId:data.parentId
                }
            }
            const bcItemIndex = this.state.breadCrumbs.findIndex(bc=>bc.parentId===data.parentId);
            let bcLocation = [...this.state.breadCrumbs];
            if(bcItemIndex<0){
                bcLocation.push({locationId:data.locationId, parentId:data.parentId,name:data.name});
            }else{
                bcLocation.splice(bcItemIndex+1);
            }
            this.context.onItemChange({id:"image360Viewer", pageName:"360º Photo View"});
            AxiosInstance().get('/api/locations',config)
                .then(response=>{
                    this.setState({
                        ...this.state,
                        data: {...response.data.location},
                        portals:[...response.data.portals],
                        pois:[...response.data.pois],
                        dataLoading:false,
                        vrEnabled:false
                    })
                })
                .catch(error=>{                    
                    this.setState({
                        ...this.state,
                        dataLoading:false,
                        vrEnabled:false
                    })
                })
                this.setState({
                    ...this.state,
                    breadCrumbs:bcLocation,
                    dataLoading:true,
                    vrEnabled:false
                })
    }

    getPortal = async(data,vrEnabled, renderState)=>{
        const currentItem = this.state.breadCrumbs[this.state.breadCrumbs.length-1]         
        let config = {                      
            params:{
                locationId:currentItem.locationId,
                parentId:data.id
            }
        }
        const bcLocation = [...this.state.breadCrumbs];
        bcLocation.push({locationId:currentItem.locationId, parentId:data.id,name:data.name});
        this.context.onItemChange({id:"image360Viewer", pageName:"360º Photo View"});
        AxiosInstance().get('/api/locations',config)
            .then(response=>{
                this.setState({
                    ...this.state,
                    data: {...response.data.location},
                    portals:[...response.data.portals],
                    pois:[...response.data.pois],
                    dataLoading:false,
                    vrEnabled,
                    renderState
                })
            })
            .catch(error=>{
                this.setState({
                    ...this.state,
                    dataLoading:false,
                    vrEnabled
                })
            })
            this.setState({
                ...this.state,
                breadCrumbs:bcLocation,
                dataLoading:true,

            })
}
    state = { 
        getAllLocations:this.getAllLocations,
        getLocation:this.getLocation,
        getPortal:this.getPortal,
        locations:[],
        breadCrumbs:[],
        dataLoading:true,
        data:null,
        portals:[],
        pois:[],
        vrEnabled:false,
        renderState:null
    }
    
    render() {
        return (
            <LocationContext.Provider value={this.state}>
                { this.props.children }
            </LocationContext.Provider>
        );
    }
}

const LocationContextConsumer = LocationContext.Consumer;
LocationContextProvider.contextType = NavContext;

export { LocationContextProvider, LocationContext, LocationContextConsumer};