import React from 'react';
import styled from 'styled-components';
// import { NavContextConsumer } from '../../utility/Contexts/NavigationContext';

import IconArrow from '../../../resources/img/icon-back.svg';

const BackIcon = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 40px;
    left: 40px;
    width: 48px;
    height: 48px;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    &::after{
        content: "";
        position: absolute;
        left :-3px;
        top: -3px;
        width :52px;
        height: 52px;
        border-radius: 50%;
        border: 1px solid #ffffff;
        display: block;
    }
`

const Welcome = ({ onClickHandler = () => alert('back') }) => {
    return(
        <BackIcon onClick={() => onClickHandler()}>
            <img src={IconArrow} alt=""/>
        </BackIcon>
    )
}

export default Welcome;