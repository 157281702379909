import React from 'react';
import styled from 'styled-components';

import Burger from '../Burger/Burger';

import IconAboutProject from '../../../resources/img/icon-about-project.svg';
import IconAboutInstitute from '../../../resources/img/icon-about-institute.svg';

const UserMenuWrapper = styled.ul`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 70px 1px 1px;
    background-color: rgba(45, 120, 101, 0.9);
    z-index: 9999;
`;

const UserMenuItem = styled.li`
    display: flex;
    flex-wrap: nowrap;
    height: 80px;
    color: #ffffff;
    list-style: none;
    z-index: 1;
    cursor: pointer;
    &:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }
`;

const IconWrapper = styled.div`
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 100px;
    height: 80px;
    &::after {
        position: absolute;
        content: "";
        display: block;
        width: 1px;
        top: 18px;
        right: 0;
        bottom: 18px;
        background-color: #ffffff;
    }
    & img {
        width: 48px;
    }
`;

const TextWrapper = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    width: 100%;
    font-size: 20px;
    text-align: center;
`;

const AppVersionContainer = styled.div`
    font-size: 12px;
    color: #ffffff;
    text-align: center;
`;

const mockMenuItems = [
    {
        id: 'aboutProject',
        pageName: 'About Project',
        icon: IconAboutProject,
    },
    {
        id: 'aboutInstitute',
        pageName: 'About Institute',
        icon: IconAboutInstitute,
    },
]

const UserMenu = ({ appVersion = '', menuItems = mockMenuItems, onMenuToggle = () => {alert('Menu toggle')}, onChangeActiveItem = () => {alert('Menu item clicked!')} }) => (
    <UserMenuWrapper>
        {menuItems.map(item => {
                return <UserMenuItem key={item.id} onClick={() => onChangeActiveItem({id:item.id,pageName:item.pageName})}>
                            {item.icon
                                ?   <IconWrapper>
                                        <img src={item.icon} alt="" />
                                    </IconWrapper>
                                :   null
                            }
                            <TextWrapper icon={item.icon} >{item.pageName}</TextWrapper>
                        </UserMenuItem>
            })
        }
        {appVersion ? <AppVersionContainer>{appVersion}</AppVersionContainer> : null}
        <Burger onClickHandler = {() => onMenuToggle()} active={true} />
    </UserMenuWrapper>
);

export default UserMenu;
