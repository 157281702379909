import React from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import * as serviceWorker from './serviceWorker';
import { NavContextProvider } from './utility/Contexts/NavigationContext';
import { LocationContextProvider } from './utility/Contexts/LocationContext';

import Router from './router';

const GlobalStyle = createGlobalStyle`
  * {
    margin:0;
    padding:0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
  }

  body {
      overscroll-behavior: none;
  }

  button, input {
    outline: none;
  }
`
const defaultView = {
  activeItem: 'splash',
  pageName: 'Splash Screen',
}

ReactDOM.render(
    <React.Fragment>
        <GlobalStyle />
            <NavContextProvider defaultView={defaultView}>
              <LocationContextProvider>
                <Router />
              </LocationContextProvider>
            </NavContextProvider>
    </React.Fragment>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
