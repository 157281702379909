import React, { Component } from 'react';
import styled from 'styled-components';
import { NavContextConsumer } from '../../utility/Contexts/NavigationContext';
import { LocationContext } from '../../utility/Contexts/LocationContext';

import PortraitBackgoundImage from '../../resources/img/splash-bg.jpg';
import LandscapeBackgoundImage from '../../resources/img/splash-bg-landscape.jpg';

const SplashWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background: url(${props => props.deviceOrientation === 'portrait' ? PortraitBackgoundImage : LandscapeBackgoundImage}) center / cover no-repeat;
`;

class Splash extends Component {
    state = {
        deviceOrientation: 'portrait'
    }

    async componentWillMount(){
        await this.context.getAllLocations();
    }

    componentDidMount() {
        this.handleWindowResize();
        window.addEventListener('resize', this.handleWindowResize);
    }

    shouldComponentUpdate(nextProps, nextState){        
        if(nextState.deviceOrientation!==this.state.deviceOrientation)
            return true;
        return false;
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize = () => {
        const deviceOrientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';

        this.setState({
            ...this.state,
            deviceOrientation
        })
    }
    
    render () {
        return (
            <NavContextConsumer>
                {({ onItemChange }) => {
                    setTimeout(() => {
                        onItemChange({
                            id: 'welcome',
                            pageName: 'Welcome Screen',
                          });
                    }, 3000);
                    return <SplashWrapper deviceOrientation={this.state.deviceOrientation} />
                }}
            </NavContextConsumer>
        )
    }
};

Splash.contextType = LocationContext;

export default Splash;