import React, { Component } from 'react';
import styled from 'styled-components';
import { NavContextConsumer } from '../../utility/Contexts/NavigationContext';

import LoginButton from '../../components/ui/Button/LoginButton';
import PortraitBackgoundImage from '../../resources/img/welcome-bg.png';
import LandscapeBackgoundImage from '../../resources/img/welcome-bg-landscape.png';

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100vw;
    height: 100vh;
    padding: 40px 50px;
    background: url(${props => props.deviceOrientation === 'portrait' ? PortraitBackgoundImage : LandscapeBackgoundImage}) center / cover no-repeat;
`;

const Text = styled.p`
    margin-bottom: 5vh;
    text-align: center;
    color: #2D7865;
`;

class Welcome extends Component {
    state = {
        deviceOrientation: 'portrait'
    }

    componentDidMount() {
        this.handleWindowResize();
        window.addEventListener('resize', this.handleWindowResize);
    }
    
    shouldComponentUpdate(nextProps, nextState){        
        if(nextState.deviceOrientation!==this.state.deviceOrientation)
            return true;
        return false;
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize = () => {
        const deviceOrientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';

        this.setState({
            ...this.state,
            deviceOrientation
        })
    }

    render () {
        return(
            <PageWrapper deviceOrientation={this.state.deviceOrientation}>
                <Text>VR 360 Nature will allow you to select location on the interactive map, view textual information about the location, view 360° photo on the mobile phone screen or view 360° photo through Virtual Reality Head Mounted Display, read textual information in 360° photo view.
                    <br />
                    <br />
                    Enjoy your virtual journey!
                </Text>
                <NavContextConsumer>
                    {({ onItemChange }) => 
                        <LoginButton onClickHandler={() => onItemChange({id:'map',pageName:'Map Screen'})}>Continue</LoginButton>
                    }
                </NavContextConsumer>
            </PageWrapper>
        )
    }
}

export default Welcome;