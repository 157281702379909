import React, { Component } from 'react';
import styled from 'styled-components';

import {Map, TileLayer, Marker, Popup} from 'react-leaflet';
import L from 'leaflet';
import { LocationContext } from '../../utility/Contexts/LocationContext';
import { NavContextConsumer } from '../../utility/Contexts/NavigationContext';

import UserMenu from '../../components/ui/UserMenu/UserMenu';
import Burger from '../../components/ui/Burger/Burger';
import icon from '../../resources/img/marker-icon.png';
import DetailsCard from '../ui/DetailsCard/DetailsCard';

const StyledMapWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const markerIconLarge = L.icon({
  iconUrl:icon,
  iconSize:[50,67],
  iconAnchor:[25,67]
})
const markerIconSmall = L.icon({
  iconUrl:icon,
  iconSize:[25,33],
  iconAnchor:[12,33]
})

class MapWrapper extends Component {
  state = {
    center:[44.3841670,20.6850566],    
    selectedMarker:"",
  }
zoom = 7;

// changePosition=(e)=>{
//   let newId = 1;
//   if(this.state.positions.length>0)
//    newId=this.state.positions[this.state.positions.length-1].id+1;
//   let newPosition = {id:newId,lat:e.latlng.lat,lng:e.latlng.lng,marker:markerIconSmall};
//   this.setState({
//     ...this.state,
//     positions:[...this.state.positions,newPosition]
//   })  
// }

changeZoom = (e)=>{
  this.zoom = e.target._zoom;
}

selectPosition = (id)=>{ 
  
  this.setState({
    ...this.state,    
    selectedMarker:id
  })
}

  render() {
    const {selectedMarker} = this.state;
    const {locations} = this.context;
     
    return (
        <StyledMapWrapper>
            <Map center={this.state.center} zoom={this.zoom} onzoomend={e=>this.changeZoom(e)} maxZoom={19} preferCanvas={true} style={{position:"absolute", top:"0", left:"0", height:"100%", width:"100%"}}>
                <TileLayer
                attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>'
                url='https://api.maptiler.com/maps/topo/{z}/{x}/{y}.png?key=jMav2oontDrA10QYZ6op'
                crossOrigin={true}
                />
                {locations.map((loc)=><Marker key={loc.id} position={[loc.latitude, loc.longitude]} icon={selectedMarker===loc.id?markerIconLarge:markerIconSmall} onClick={()=>{this.selectPosition(loc.id)}}>
                <Popup>
                    <DetailsCard selectedMarker={selectedMarker} cardTitle={loc.name} longitude={loc.longitude} latitude={loc.latitude} descriptionText={loc.description} onDelete={() => {}}/>
                </Popup>
                </Marker>
                )}
            </Map>
            <NavContextConsumer>
                {({ menuOpen, onMenuToggle, onItemChange }) => 
                        <React.Fragment>
                            {menuOpen
                                ?   <UserMenu onMenuToggle={onMenuToggle}  onChangeActiveItem = {onItemChange} />
                                :   <Burger onClickHandler = {() => onMenuToggle()} />
                            }
                        </React.Fragment>
                    }
            </NavContextConsumer>
        </StyledMapWrapper>
    )
  }
}

MapWrapper.contextType = LocationContext;

export default MapWrapper;

