import React, { Component } from 'react';

const NavContext = React.createContext();

class NavContextProvider extends Component {  
    handleChangeActiveItem = page => {
        this.setState({
            ...this.state,
            activeItem: page.id,
            pageName: page.pageName,
            parentId: page.parentId||"",
            menuOpen: false,
        })
    }

    handleMenuToggle = () => {
        this.setState({
            ...this.state,
            menuOpen: !this.state.menuOpen
        })
    }

    state = { 
        activeItem: this.props.defaultView.activeItem || null,
        pageName: this.props.defaultView.pageName,
        onItemChange: this.handleChangeActiveItem,
        onMenuToggle: this.handleMenuToggle,
        menuOpen: false,
        parentId:""
    }
    
    render() {
        return (
            <NavContext.Provider value={this.state}>
                { this.props.children }
            </NavContext.Provider>
        );
    }
}

const NavContextConsumer = NavContext.Consumer;

export { NavContextProvider, NavContext, NavContextConsumer};