import React from 'react';
import styled from 'styled-components';
import { LocationContextConsumer } from '../../../utility/Contexts/LocationContext';
import Button from '../Button/Button';

const CardWrapper = styled.div`
    position:relative;
    width: 100%;
    max-width: 400px;
    color:#2d7865;
    text-align:left;
    background-color: #2d7865;
`

const ContentWrapper = styled.div`
    position: relative;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    background-color: #ffffff;
    border-radius: 5px 30px 5px 5px;
`

const Heading = styled.h3`
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 15px;
`

const GeolocWrapper = styled.div`
    margin-bottom:15px;
`

const DescriptionWrapper = styled.p`
    max-height: 200px;
    overflow-y: scroll;
    outline: none;
`

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const DetailsCard = ({ cardTitle,longitude,latitude,descriptionText, selectedMarker })=>{
    return(
        <CardWrapper>
            <ContentWrapper>
                <Heading>{cardTitle}</Heading>
                {longitude&&latitude
                ?   <GeolocWrapper>
                        <p>Longitude: {longitude}</p>
                        <p>Latitude: {latitude}</p>
                    </GeolocWrapper>
                : null}
                <DescriptionWrapper>{descriptionText}</DescriptionWrapper>
            </ContentWrapper>
            <ButtonsWrapper>
                <LocationContextConsumer>
                    {({ getLocation }) => <Button onClickHandler={() => getLocation({locationId:selectedMarker,parentId:selectedMarker})}>VIEW 360˚ PHOTO</Button>}                    
                </LocationContextConsumer>
            </ButtonsWrapper>
        </CardWrapper>
    )
}

export default DetailsCard;