import axios from 'axios';
import config from '../../config/default.json';

export function AxiosInstance(){
    var instance = axios.create({
        baseURL: config.serverUrl
        // baseURL:'http://192.168.0.23:5000'
    });
    
    return instance;
}