import React from 'react';
import styled from 'styled-components';
import { NavContextConsumer } from '../../utility/Contexts/NavigationContext';

import UserMenu from '../../components/ui/UserMenu/UserMenu';
import Burger from '../../components/ui/Burger/Burger';
import BackButton from '../../components/ui/Button/BackButton';
import Image from '../../resources/img/about-institute.png';

const PageWrapper = styled.div`
    width: 100%;
    min-height: 100%;
    padding-bottom: 100px;
    background: rgba(172, 234, 211, 0.4);
`;

const ImageWrapper = styled.div`
    height: 35vh;
    background: url(${Image}) center / cover no-repeat;
`;

const ContentWrapper = styled.div`
    padding: 20px 40px;
`;

const Heading = styled.h1`
    position: relative;
    margin-bottom: 20px;
    padding-left: 5px;
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
    color: #2D7865;
    &::before {
        position: absolute;
        content: '';
        display: block;
        top: -1px
        left: 0;
        bottom: -1px;
        width: 1px;
        background-color: #2D7865;
    }
`;

const Text = styled.p`
    font-size: 16px;
    text-align: justify;
    color: #2D7865;
`;

const AboutInstitute = () => {
    return(
        <PageWrapper>
            <ImageWrapper />
            <ContentWrapper>
                <Heading>About Institute</Heading>
                <Text>Institute of Lowland Forestry and Environment (ILFE) is the part of University of Novi Sad. Institute was established in 1958. ILFE has 35 employees, from which 18 PhDs in different disciplines (forestry, biology, ecology and biochemistry). ILFE is dealing with biological, ecological and environmental aspects of forest ecosystems. ILFE has a very close cooperation with the governmental institutions, forest public enterprises, as well as with private sector. ILFE has long tradition in establishing new forest areas (since its initial founding) and experience in advanced scientific methods, including state-of-the art GIS applications, such as 3D maping and simulations. ilfe.org</Text>
            </ContentWrapper>
            <NavContextConsumer>
                {({ menuOpen, onMenuToggle, onItemChange }) => 
                    <React.Fragment>
                        {menuOpen
                            ?   <UserMenu onMenuToggle={onMenuToggle}  onChangeActiveItem = {onItemChange} />
                            :   <React.Fragment>
                                    <BackButton onClickHandler={() => onItemChange({id:'map',pageName:'Map Screen'})} />
                                    <Burger onClickHandler = {() => onMenuToggle()} />
                                </React.Fragment>
                        }
                    </React.Fragment>
                }
            </NavContextConsumer>
        </PageWrapper>
    )
}

export default AboutInstitute;