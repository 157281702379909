import React from 'react';
import styled from 'styled-components';
import { NavContextConsumer } from './utility/Contexts/NavigationContext';

import Map from './components/common/Map';
import Image360Viewer from './pages/360Viewer/360Viewer';
import Splash from './pages/Splash/Splash';
import Welcome from './pages/Welcome/Welcome';
import AboutInstitute from './pages/AboutInstitute/AboutInstitute';
import AboutProject from './pages/AboutProject/AboutProject';

const ContentWrapper = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
`;

const pages = {
    splash:e=><Splash />,
    welcome:e=><Welcome />,
    map:e=><Map />,
    image360Viewer:e=><Image360Viewer parentId={e}/>,
    aboutInstitute:e=><AboutInstitute />,
    aboutProject:e=><AboutProject />,
}

const Router = () => {
    return (
        <ContentWrapper>
            <NavContextConsumer>
                {({ activeItem, parentId }) => {
                        return pages.hasOwnProperty(activeItem)
                        ? pages[activeItem](parentId||null)
                        : <p>404 Page not found</p>
                }
                }
            </NavContextConsumer>
        </ContentWrapper>
    )
}

export default Router;